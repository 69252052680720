.driver__dashboard__container__mob {
  display: none;
}

.show__mobile {
  display: none;
}

.add__drop__container__mob {
  display: none;
}

/* Driver Dashboard Container */
.driver__dashboard__container__desktop {
  display: flex;
  flex-direction: row;
}

/* Driver Dashboard Panel */
.driver__dashboard__panel {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  margin: 1em;
}

/* Numbers of the driver dashboard */
.driver__dashboard__numbers {
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  gap: 1em;
  width: 88vw;
  justify-content: center;
  align-items: center;
}

.driver__dashboard__numbers__in__progress {
  background-color: var(--inprogress);
  height: 7em;
  width: 7em;
  border-radius: 7em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.driver_db_number_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.driver__dashboard__numbers__todo {
  background-color: var(--yellow);
  height: 7em;
  width: 7em;
  border-radius: 7em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.driver__dashboard__numbers__dropped {
  background-color: var(--secondary);
  height: 7em;
  width: 7em;
  border-radius: 7em;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add Drop */
.add__drop__container__mob {
  display: none;
}

.driver__add__drop__container__desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2em;
}

.driver__add__drop__search {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.driver__add__drop__location {
  display: flex;
  flex: 1;
  justify-items: flex-start;
  align-items: flex-start;
  gap: 1em;
}

.add__drop__drop__location__details {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.dadl__address {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5em;
}

/* Current Drop */
.current__drop__container {
  display: none;
}
.current__drop__container__desktop {
  display: flex;
  flex-direction: column;
  margin: 4em 1em 1em 1em;
  flex-wrap: wrap;
  width: 100%;
}
.cdc__details {
  display: flex;
  flex-direction: row;
  gap: 1em;
  flex-wrap: wrap;
}
.cdc__table {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2em;
  margin-top: 1em;
}

.cdc__table th {
  padding: 1em;
}

.current__drop__location {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.cdl__details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1em;
  gap: 0.5em;
}

.cdl__location {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.current__drop__location button {
  align-self: center;
}

.current__drop__image__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3em;
}

.current-drop-image {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 150px;
  height: 150px;
  border-radius: 1em;
  box-shadow: 12px 16px 37px -20px rgba(176,176,176,1);
  -webkit-box-shadow: 12px 16px 37px -20px rgba(176,176,176,1);
  -moz-box-shadow: 12px 16px 37px -20px rgba(176,176,176,1);
}

.current__drop__comment {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding-right: 7%;
}

.current__drop__comment input {
  height: 2em;
}

.current__drop__comment input::placeholder {
  display: flex;
  position: absolute;
  top: 1em;
}

.current__drop__comment input:focus {
  display: flex;
  transform: scale(1.1);
  transition: transform 500ms ease-in-out;
}

/* Driver History */
.driver__history__container__mob {
  display: none;
}
.driver__history__container__desk {
  display: flex;
  flex-direction: row;
}
.driver__history__container {
  display: flex;
  flex-direction: column;
  width: 88vw;
  justify-content: flex-start;
  align-items: center;
  margin: 1em;
}

.driver__history__container h1 {
  align-self: flex-start;
}

.driver__history__container h4 {
  margin-top: 5em;
}
.driver__history__search {
  display: flex;
  flex-direction: row;
  gap: 2em;
  margin-top: 1em;
}

.driver__history__table {
  display: flex;
  flex-direction: row;
  margin-top: 2em;
}

.driver_repeat_run {
  text-decoration: underline;
}

.driver_repeat_run:hover {
  cursor: pointer;
}
.dht__heading th {
  padding: 1em;
}

/* Driver-Contact */
.driver__contact__desk {
  display: flex;
  flex-direction: row;
}

.message__writter__container__mob {
  display: none;
}
.driver__contact__mob {
  display: none;
}
.message__writter__container__mob {
  display: none;
}
.administrator__messaging__container__mob {
  display: none;
}
.driver__message__container {
  width: 88vw;
  margin: 1em;
}

.administrator-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message__writter__container__desk {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 1.5em;
  align-self: start;
  justify-self: flex-start;
}

.message__writter__desk {
  display: flex;
  flex-direction: row;
  border: 0px solid black;
  width: 20em;
  align-self: flex-end;
  padding: 1.5em;
  background-color: var(--bg-2);
  border-radius: 1em;
}

.message__writter__mob:focus {
  outline: none;
}

.message__writter__mob {
  align-self: center;
}

.m__wm__button__send {
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0px;
  width: 3.5em;
  height: 3.5em;
  border-radius: 3.5em;
  margin-right: 1em;
  background-color: var(--bg-2);
  transition: background-color 0.3s ease-in-out;
}

.m__wm__button__send:hover {
  background-color: var(--bg-1);

} 
/* Automatically get classes from administrator
* Change administrator classs or add new classes here.
*/

@media (max-width: 1280px) {
  .driver__dashboard__numbers {
    display: flex;
    align-self: center;
    flex-direction: row;
    margin-top: 2em;
    gap: 1em;
    width: 70vw;
    justify-content: center;
    align-items: center;
  }

  .driver__add__drop__container__desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2em;
    width: 70vw;
  }

  .cdc__table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
  }

  .administrator__settings__content {
    display: flex;
    flex-direction: column;
  }

  .driver__message__container {
    width: 100%;
    margin: 1em;
  }
}

@media (max-width: 1100px) {
  .cdl__details {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cdc__details {
    gap: 2em;
  }
}

@media (max-width: 1024px) {
  .driver__dashboard__numbers {
    display: flex;
    align-self: center;
    flex-direction: row;
    margin-top: 2em;
    gap: 1em;
    width: 70vw;
    justify-content: center;
    align-items: center;
  }

  .driver__add__drop__container__desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2em;
    width: 70vw;
  }

  .cdc__table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
    margin-left: auto;
    margin-right: 17%;
  }

  .administrator__settings__content {
    display: flex;
    flex-direction: column;
  }

  .administrator__messaging__container {
    display: flex;
    flex-direction: column;
    height: 65vh;
    width: 40vw;
    border: 1px solid black;
    border-radius: 1em;
    overflow-y: auto;
    padding: 1em;
    margin: 1em 1em 1em 1em;
  }

  .driver__messaging__container {
    display: flex;
    flex-direction: column;
    height: 65vh;
    width: 40vw;
    border: 1px solid black;
    border-radius: 1em;
    overflow-y: auto;
    padding: 1em;
    margin: 1em 1em 1em 1em;
  }

  .message__writter__container__desk {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 41vw;
    gap: 1.5em;
    align-self: start;
    justify-self: flex-start;
  }

  .side__panel__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 0 !important;
    max-width: 12vw;
    font-size: 1.1em;
    min-width: 10.4em;
    height: 800dvh;
    background-color: var(--bg-1);
    padding: 0.1em;
  }

  .side__panel__item .heading-3 {
    font-size: 0.84rem;
  }

  .current__drop__comment {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    padding-right: 11%;
  }
}

@media (max-width: 900px) {
  .driver__history__table {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
    width: 60%;
    padding-right: 50%;
  }
}

@media (max-width: 850px) {
  .current__drop__container__desktop {
    display: flex;
    flex-direction: column;
    margin: 1em 1em 1em 0.5em;
    flex-wrap: wrap;
    width: 80%;
  }
}

@media (max-width: 825px) {
  .current__drop__container__desktop {
    display: flex;
    flex-direction: column;
    margin: 1em 1em 1em 0.5em;
    flex-wrap: wrap;
    width: 80%;
  }
}

@media (max-width: 800px) {
  .message__writter__container__desk {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 48vw;
    gap: 1.5em;
    align-self: start;
    justify-self: flex-start;
  }

  .current__drop__container__desktop {
    display: flex;
    flex-direction: column;
    margin: 1em 1em 1em 0.2em;
    flex-wrap: wrap;
    width: 80%;
  }

  .administrator__settings__content h1 {
    margin: 3em 0 0 0.5em;
    align-self: center;
  }

  .administrator__settings__content {
    display: flex;
    flex-direction: column;
  }

  .side__panel__container {
    display: none;
  }

  .current__drop__location {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    font-size: 14px;
  }

  .current__drop__comment {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    padding-right: 12%;
  }

  .cdc__table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
    margin-left: auto;
    margin-right: 17%;
  }

  .administrator__messaging__container {
    display: flex;
    flex-direction: column;
    height: 65vh;
    width: 47vw;
    border: 1px solid black;
    border-radius: 1em;
    overflow-y: auto;
    padding: 1em;
    margin: 1em 1em 1em 1em;
  }

  .administrator__settings__content button {
    justify-self: center;
    align-self: center;
    width: 150px;
    height: 40px;
  }

  .driver__history__table {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
    margin-left: 0;
    padding-right: 50%;
  }

  .heading-2 dht__heading {
    font-family: "Comfortaa", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size: 0.9rem;
    margin: 0;
  }

  .driver__history__search {
    display: flex;
    flex-direction: row;
    gap: 2em;
    margin-top: 1em;
    margin-left: 3em;
  }

  .driver__history__container h4 {
    margin-top: 5em;
    margin-left: 8%;
  }

  .individual__drop__container {
    display: flex;
    flex-direction: column;
    margin: 1em auto 0 0em;
  }

  .individual-drop-data {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .idc-title {
    margin-left: 1em;
  }

  .individual__drop__at__container {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
  }

  .individual-drop-upload {
    width: fit-content;
    align-self: center;
  }

  .individual__drop__image__container {
    display: flex;
    flex-direction: row;
    margin: 0px 1px 1px 1px;
    gap: 0.5em;
    overflow-x: scroll;
  }

  .image-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
    overflow-x: auto;
    position: relative;
    margin: 10px;
    height: 170px;
  }

  .delete-image-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border-radius: 100%;
    border: none;
    width: 15px !important;
    height: 15px;
    cursor: pointer;
    padding: 0.8em;
    font-size: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .individual__drop__time {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 0.5em 0 0 1em;
    flex: 1;
  }

  .individual__drop__address {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.5em;
  }
  .individual__drop__address p {
    margin: 0 1em;
  }

  .individual-drop-state {
    background-color: var(--inprogress);
    padding: 0.5em 1em;
    width: fit-content;
    text-align: center;
    border-radius: 1em;
    margin-top: 0.5em;
  }

  .individual-drop-state-inprogress {
    background-color: var(--inprogress);
  }

  .individual-drop-state-completed {
    background-color: var(--secondary);
  }

  .individual__drop__container button {
    width: 200px;
    align-self: center;
  }

  .add_comment_drop_finish {
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .add_comment {
    margin: 1em auto;
    border-width: 0px;
    border-radius: 1em;
    padding: 1em;
    width: 90%;
    font-family: "comfortaa", sans-serif;
    background-color: var(--bg-2);
  }

  .add_comment:focus {
    outline: none;
  }

  .complete_drop {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }

  .complete_drop button {
    width: 100%;
  }

  .addimages-input {
    display: none;
  }

  .bottom__panel__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100vw;
    background-color: var(--bg-1);
    position: fixed;
    align-items: center;
    text-align: center;
    bottom: 0;
    height: 4em;
  }
}

@media (max-width: 600px) {
  .driver__dashboard__container__desktop {
    display: none;
  }
  .show__mobile {
    display: block;
  }

  .driver__dashboard__container__mob {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow-y: auto;
    margin-bottom: 5em;
    overflow-x: hidden;
  }

  .driver__dashboard__content {
    display: flex;
    flex-direction: column;
  }

  .driver__dashboard__content h1 {
    left: 0;
    justify-content: center;
    align-items: center;
    margin: 0.5em;
  }

  .administrator__settings__content {
    display: flex;
    flex-direction: column;
  }

  .input {
    border-width: 0px;
    padding: 0.5em;
    background-color: var(--bg-2);
    width: 16em;
    border-radius: 0.5em;
    font-family: "comfortaa", sans-serif;
  }

  /* Add Drop */
  .add__drop__container__mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin: 2em 1em 2em 1em;
  }
  .driver__add__drop__container__desktop {
    display: none;
  }
  .driver__dashboard__numbers {
    width: 100vw;
  }

  .add__drop__container {
    display: flex;
    flex-direction: column;
    margin: 2em auto;
  }

  .add__drop__container h2 {
    display: flex;
    position: absolute;
    left: 0;
    margin-left: 2em;
  }

  .search__google {
    margin-top: 3em;
  }

  .add__drop__container p {
    align-self: center;
  }

  .add__drop__container input {
    margin-top: 0.5em;
    border-width: 0em;
    background-color: var(--bg-2);
    padding: 1em;
  }

  .add__drop__container input::placeholder {
    font-family: "Comfortaa", sans-serif;
  }

  .add__drop__container input:focus {
    outline: 0px;
  }

  .add__drop__drop__location {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-top: 1em;
  }

  .add__drop__drop__location p {
    align-self: start;
  }

  .add__drop__container button {
    margin-top: 2em;
  }

  /* Current Drop */
  .current__drop__container__desktop {
    display: none;
  }
  .current__drop__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .current__drop__card {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-1);
    justify-content: center;
    align-items: center;
    padding: 1em 3em;
    border-radius: 1em;
    width: 70%;
    margin: 1em auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: transform 3 ease-in-out;
  }

  .current__drop__col1 {
    gap: 0.5em;
    justify-content: space-between;
    width: 100%;
  }

  .driver-his-date-time {
    justify-content: space-between;
    margin-top: 1em;
    width: 100%;
  }

  .current__drop__col1 p {
    margin-left: 0.5em;
  }

  .current__drop__col2 {
    margin-top: 2em;
    gap: 1em;
    width: 100%;
  }

  .current-drop-r1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .current-drop-r1 button {
    background-color: var(--bg-2);
  }

  .current-drop-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .current-drop-hed-btn {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
  }

  .current-drop-move {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.1em;
  }

  .current-drop-move button {
    border-width: 0px;
    border-radius: 1em;
    padding: 0.2em;
  }

  .current-drop-move button:hover {
    background-color: var(--primary);
  }

  .current-drop-row button {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .current-drop-row button:hover {
    background-color: var(--primary);
    color: white;
  }

  .current__drop__col2 button {
    border: 2px solid var(--inprogress);
  }

  .current-drop-status {
    padding: 0.5em 1em;
    width: fit-content;
    border-radius: 1em;
  }

  .current-drop-status-todo {
    background-color: var(--yellow);
  }

  .current-drop-status-inprogress {
    background-color: var(--inprogress);
  }

  .current-drop-status-completed {
    background-color: var(--secondary);
  }

  .current-drop-dd-button {
    border: 2px solid var(--inprogress);
    background-color: var(--bg-1);
    font-family: "comfortaa", sans-serif;
    padding: 0.5em 1em;
    border-radius: 1em;
    transition: all 0.3s ease-in-out;
  }

  .current-drop-dd-button:hover {
    background-color: var(--inprogress);
    color: black;
  }

  /* Individual Drop  */
  .individual__drop__container {
    display: flex;
    flex-direction: column;
    margin: 1em auto 0 0em;
  }

  .drop-container-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    margin-left: 1em;
  }

  .individual-drop-data {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .idc-title {
    margin-left: 1em;
  }

  .individual__drop__at__container {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
  }

  .individual-drop-upload {
    width: fit-content;
    align-self: center;
  }

  .individual__drop__time {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 0.5em 0 0 1em;
    flex: 1;
  }

  .individual__drop__address {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.5em;
  }
  .individual__drop__address p {
    margin: 0 1em;
  }

  .individual-drop-state {
    background-color: var(--inprogress);
    padding: 0.5em 1em;
    width: fit-content;
    text-align: center;
    border-radius: 1em;
    margin-top: 0.5em;
  }

  .individual-drop-state-inprogress {
    background-color: var(--inprogress);
  }

  .individual-drop-state-completed {
    background-color: var(--secondary);
  }

  .individual__drop__container button {
    width: 200px;
    align-self: center;
  }

  .add_comment_drop_finish {
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .add_comment {
    margin: 1em auto;
    border-width: 0px;
    border-radius: 1em;
    padding: 1em;
    width: 90%;
    font-family: "comfortaa", sans-serif;
    background-color: var(--bg-2);
  }

  .add_comment:focus {
    outline: none;
  }

  .complete_drop {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }

  .complete_drop button {
    width: 100%;
  }

  /* Driver History */

  .driver__history__container__desk {
    display: none;
  }
  .driver__history__container__mob {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    margin-bottom: 5em;
    overflow-x: hidden;
  }

  .driver__history__container__mob h1 {
    margin: 1em 0 0 1em;
  }

  .btn__repeat_all__runs {
    width: 50%;
    align-self: center;
    margin-top: 1em;
  }

  .driver-search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .driver__history__search__date {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin: 0.5em;
    font-size: 1em;
  }

  .date-margin {
    margin-left: 1.5em;
  }

  .driver-history-container{
    display: flex;
    flex-direction: row;
  }

  .re-run-button {
    margin: 1em auto;
    width: fit-content;
    border: 2px solid var(--primary);
    border-radius: 1em;
  }

  .re-run-button:hover {
    border-color: white;
    background-color: var(--primary);
    color: white;
  }

  .driver__history__search__date p {
    text-align: right;
    font-size: 16px;
  }

  .driver__history__search__address {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin: 0.5em;
  }

  .driver__history__container input {
    align-self: flex-end;
  }

  .driver__history__card {
    background-color: var(--bg-1);
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .driver__history__card {
    margin-top: 1em;
  }

  .driver-his-no-data-available-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: var(--bg-1);
  }
  /* Driver-Contact */
  .driver__contact__mob {
    display: flex;
    flex-direction: column;
  }
  .driver__contact__desk {
    display: none;
  }

  .mobile-msg-container {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-2);
    padding: 2em;
    height: 61vh;
    overflow-y: auto;
    border-radius: 1em;
  }

  .message__writter__container__mob {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-right: 1em;
    padding-left: 8%;
  }
  .administrator__messaging__container__mob {
    display: flex;
  }

  .administrator__settings__content h1 {
    margin: 3em 0 0 0.2em;
    align-self: center;
  }

  .bottom__panel__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100vw;
    background-color: var(--bg-1);
    position: fixed;
    align-items: center;
    text-align: center;
    bottom: 0;
    height: 4em;
  }
}

@media (max-width: 416px) {
  .mobile-msg-container {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-2);
    padding: 2em;
    height: 59vh;
    overflow-y: auto;
    border-radius: 1em;
  }

  .message__writter__container__mob {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-right: 1em;
    padding-left: 6.5%;
  }
}

/* Testing Components */

.testing-container {
  background-color: lightblue;
}

.testing-card {
  background-color: lightgoldenrodyellow;
}
