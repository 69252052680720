.privacy-main-container {
  margin: 2em;
  display: flex;
  flex-direction: column;
}

.privacy-image-container{
  display: flex;
}

.privacy-image-container > img {
  max-width: 500px;
}

.privacy-policy-button{
  width: fit-content;
  background-color: white;
  border: none;
}

.privacy-policy-button:hover{
  cursor: pointer;
}

@media screen and (max-width:600px) {
  .privacy-main-container{
    margin: 1em;
    display: flex;
    flex-direction: column;
  }
  
  .title {
    font-family: "Patua One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    margin: 0;
  }
  
  .heading-1 {
    font-family: "Patua One", serif;
    font-style: normal;
  }
  
  .heading-2 {
    font-family: "Comfortaa", sans-serif;
    font-style: normal;
  }
  
}


