/* Global styling */
ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li{
  list-style-type: none;
  margin: 0;
  
}


/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Patua+One&display=swap");

.title {
  font-family: "Patua One", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  margin: 0;
}

.heading-1 {
  font-family: "Patua One", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
  margin: 0;
}

.heading-2 {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
  margin: 0;
}

.heading-3 {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: bolder;
  font-style: normal;
  font-size: 0.8rem;
  margin: 0;
}

.text {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  margin: 0;
}

/* input placeholders */
.input::placeholder{
  font-family: "Comfortaa", sans-serif;
  font-size: 0.8rem;
}

/* Notifications */
.notifications{
  font-family: "Comfortaa", sans-serif;
}



/* Colors */
:root {
  --primary: #056617;
  --secondary: #a6c8a9;
  --bg-1: #f1f7f3;
  --bg-2: #e9e9ee;
  --text-1: #000000;
  --text-2: #1f384c;
  --inprogress: #939fec;
  --lblue: #5a6acf;
  --red: #ec5252;
  --yellow:#f9e076;
}

/* Tags */
.inprogress-tag {
  padding: 0.5em 1em;
  background-color: var(--inprogress);
  border-radius: 2em;
}

.dropped-tag {
  padding: 0.5em 1em;
  background-color: var(--secondary);
  border-radius: 2em;
}
/* Layouts  and common elements*/
.common__row {
  display: flex;
  flex-direction: row;
}

.common__column {
  display: flex;
  flex-direction: column;
}

.input {
  border-width: 0px;
  padding: 0.5em;
  background-color: var(--bg-2);
  width: 20em;
  border-radius: 0.5em;
  font-family: "comfortaa", sans-serif;
}

.input:focus {
  outline: 0px;
}

.button {
  border-width: 0em;
  padding: 1em;
  border-radius: 0.5em;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  margin: 0;
}

.button:hover{
  transition: background-color 0.3s ease-in-out;
}

.btn-login{
  border: 2px solid var(--primary);
  padding: 1em;
  border-radius: 0.5em;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight:700;
  font-style: normal;
  font-size: 0.75rem;
  margin: 0;
  width: 60%;
  align-self: center;
  transition: all 0.3s ease-in-out;
}

.btn-login:hover{
  background-color: #056617;
  color: white;
}

.button-1 {
  background-color: var(--bg-1);
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.button-1:hover {
  background-color: var(--secondary);
}

.button-2 {
  color: var(--lblue);
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.button-2:hover {
  background-color: var(--lblue);
  color: white;
}

.button-3 {
  color: var(--red);
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.button-3:hover {
  background-color: var(--red);
  color: white;
}

/* Special Purposes */
/* Remove Link text deco */
.txt-deco-rem{
  text-decoration: none;
  color: black;
}

@media (max-width: 1024px) {
  .heading-3 {
    font-size: 1rem;
  }

  .text {
    font-size: 0.85rem;
  }
}
